import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const VideoPage = props => {
  const { pageContext } = props
  const { title, embedCode } = pageContext
  const releaseDate = pageContext?.releaseDate?.value || "Unknown"
  return (
    <Layout>
      <div
        className="p-4 px-2 px-md-5 position-relative"
        style={{ zIndex: "2" }}
      >
        <div className="py-4">
          <div
            dangerouslySetInnerHTML={{ __html: embedCode }}
            className="iframe-container"
          />
        </div>
        <h3>{title}</h3>
        <div>{releaseDate}</div>
      </div>
      <p className="py-5" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/videos" className="read-more white">
          See all videos >>
        </Link>
      </p>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export default VideoPage
